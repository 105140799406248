<template>
    <div v-loading="load" class="info-list">
        <Header/>
        <div class="blank20"/>
        <div v-if="obj.create_time" class="main-page-content contain-left-right">
            <div class="left-content" style="width:1200px">
                <div class="title-header">
                    <span class="weizhi">
                        <i class="fa fa-home">
                        </i> 当前位置：<a href="/">网站首页</a>
                        &gt; <a :href="`/info?type=${Number(obj.type)}`">{{obj.type|formatTypeName}}</a> 
                    </span>
                </div>
                <div class="content_header">
                    <h1>{{obj.title}}</h1>
                    <div class="header_msg">
                        <span> 
                            <i class="fa icon-bianzu4 font-blue"></i>{{Number(obj.views)}} 
                        </span>
                        <span style="margin-left:10px"> <i class="fa icon-rili font-blue"></i>{{obj.create_time}}</span>
                        <!-- <span> <i class="fa fa-eye font-blue"></i><a href="javascript:;"><span id="dr_show_hits_258">211</span>次</a></span> -->
                    </div>
                </div>
                <div class="content_con" v-html="obj.content"> 
                </div>
            </div>
        </div>
        <div class="blank20"/>
        <Footer/>
    </div>
</template>
<script>
export default {
    data() {
        return {
            type:1,
            obj:{},
            load:false,
        }
    },
    filters:{
        formatTypeName(val){
            let returnVal;
            switch (Number(val)) {
                case 0:
                    returnVal = '集团动态';
                    break;
                case 1:
                    returnVal = '通知公告';
                    break;
            }
            return returnVal;
        }
    },
    components:{
    },
    async beforeMount() {
        if(this.$route.query.id){
            this.load = true;
            const res = await this.$http.get('/api/index/getNewsDetail',{id:this.$route.query.id});
            this.load = false;
            if(res){
                this.obj = res;
            }
        }
    },
    created() {
        // if(this.$route.params.id){
        //     this.type = this.$route.params.type;
        // }
    },
}
</script>
<style lang="scss" scoped>
.tit-list {
    height: 50px;
    line-height: 50px;
}
.weizhi{
    font-size: 14px;
    line-height: 50px;
    color: #224899;
    margin-left: 10px;
}
.content_header{ 
    padding:20px 0px 20px;
    h1{ font-size:22px; color:#000; height:40px; line-height:40px;}
    .header_msg{ 
        color:#999; font-size:14px; padding:5px 0 15px 0; 
        i{ margin:0 3px;}
    }
}
.weizhi a {
    font-size: 14px;
    line-height: 50px;
    padding: 0 5px;
    color: #606266;
}
a, a:link {
    cursor: pointer;
    color: #333;
    text-decoration: none;
}
a:hover{
    color: #224899;
}
</style>
